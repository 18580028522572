import React from "react";
import PageLayout from "../components/PageLayout";
import PageContent from "../components/PageContent";
import PageBoundary from "../components/PageBoundary";
import LoadingStatus from "../components/LoadingStatus";

const ErrorPage: React.FC = () => {
  const tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  return (
    <PageLayout seo={tempSEOData} showNav={false} showFooterDocs={false}>
      <PageContent className="flex flex-col">
        <PageBoundary className="flex flex-col flex-1">
          <LoadingStatus loadingType="page_not_found" />
        </PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default ErrorPage;
